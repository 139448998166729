import { useRoutes } from "react-router-dom";
import Haytararutyunner from "./Pages/Haytararutyunner";
import Norutyunner from "./Pages/Norutyunner";
import Gravorner from "./Pages/Gravorner";
import GaxtniNamakner from "./Pages/GaxtniNamakner";
import FizmatOverflow from "./Pages/FizmatOverflow";
import Mrcuytner from "./Pages/Mrcuytner";
import MerMasin from "./Pages/MerMasin";
import OverflowSinglePage from "./Pages/FizmatOverflow/FizmatOverflowSinglePage";
import Kap from "./Pages/Kap";
import TournamentGrid from "./Pages/Mrcuytner/TournamentGrid";

const routes = [
    {
        path: "*",
        element: <Norutyunner />,
    },
    {
        path: "/",
        element: <Norutyunner />,
    },
    {
        path: "Norutyunner",
        element: <Norutyunner />,
    },
    {
        path: "Haytararutyunner",
        element: <Haytararutyunner />,
    },
    {
        path: "Gravorner",
        element: <Gravorner />,
    },
    {
        path: "Gaghtni_Namakner",
        element: <GaxtniNamakner />,
    },
    {
        path: "PhysMath_overflow",
        children: [
            {
                path: "",
                element: <FizmatOverflow />,
            },
            {
                path: ":slug",
                element: <OverflowSinglePage />,
            },
        ],
    },
    {
        path: "Mrcuytner",
        children: [
            {
                path: "",
                element: <Mrcuytner />,
            },
            {
                path: ":slug",
                element: <TournamentGrid />,
            },
        ],
    },
    {
        path: "Mer_Masin",
        element: <MerMasin />,
    },
    {
        path: "Kap",
        element: <Kap />,
    },
];

const Routes = () => useRoutes(routes);

export default Routes;
