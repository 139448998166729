import React, { createContext, useContext, useReducer, useEffect } from "react";

const AuthContext = createContext();

const initialState = {
    isAuthenticated: false,
    token: null,
};

const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                ...state,
                isAuthenticated: true,
                token: action.payload,
            };
        case "LOGOUT":
            return initialState;
        default:
            return state;
    }
};

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {
        // Check for a stored token in localStorage
        const storedToken = localStorage.getItem("token");
        if (storedToken) {
            // If a token is found, log in the user
            signIn(storedToken);
        }
    }, []);

    const signIn = (token) => {
        // Store the token in localStorage
        localStorage.setItem("token", token);
        dispatch({ type: "LOGIN", payload: token });
    };

    const logout = () => {
        // Remove the token from localStorage on logout
        localStorage.removeItem("token");
        dispatch({ type: "LOGOUT" });
    };

    return (
        <AuthContext.Provider value={{ ...state, signIn, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    return useContext(AuthContext);
};

export { AuthProvider, useAuth };
