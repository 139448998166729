import "./style.css";
import avatarLogo from "../../../Components/Logos/Account Image.jpg";
import { Image } from "antd";
import gravorImage from "../../../Components/Logos/gravorImage.png";
import { Input, Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
const fileList = [
    {
        uid: "0",
        name: "xxx.png",
        status: "uploading",
        percent: 33,
    },
    {
        uid: "-1",
        name: "yyy.png",
        status: "done",
        url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
        thumbUrl:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
    {
        uid: "-2",
        name: "zzz.png",
        status: "error",
    },
];
const OverflowSinglePage = () => {
    return (
        <div className="overflowSinglePageWrapper">
            <div className="questionWrapper">
                <div className="titleWrapper">
                    <img src={avatarLogo} alt="Face" />
                    <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                </div>
                <div className="imagesWrapper">
                    <Image.PreviewGroup items={[gravorImage, gravorImage]}>
                        <Image width={300} src={gravorImage} />
                        <Image width={300} src={gravorImage} />
                    </Image.PreviewGroup>
                </div>
                <p className="date">28.06.2023</p>
            </div>
            <div className="answerWrapper">
                <div className="titleWrapper">
                    <img src={avatarLogo} alt="Face" />
                    <p>Dwayne Johnson / 11ե / 23․09․2023</p>
                </div>
                <p>
                    12e i Gevy amenaujex cragravoroxna es ashxarhi vri ara jogaq
                    vabshe saxd lorem ipsum dor Gevorg en amet siu messi ronaldo
                    apres du halals this is ice cold salad varung livin in the
                    city two owns yeah yeah yeah billi eilsh hotling bling
                    havayi hovo kovo yeee
                </p>
                <div className="imagesWrapper">
                    <Image.PreviewGroup items={[gravorImage, gravorImage]}>
                        <Image width={300} src={gravorImage} />
                        <Image width={300} src={gravorImage} />
                    </Image.PreviewGroup>
                </div>
            </div>
            <div className="answerWrapper">
                <div className="titleWrapper">
                    <img src={avatarLogo} alt="Face" />
                    <p>Dwayne Johnson / 11ե / 23․09․2023</p>
                </div>
                <p>
                    12e i Gevy amenaujex cragravoroxna es ashxarhi vri ara jogaq
                    vabshe saxd lorem ipsum dor Gevorg en amet siu messi ronaldo
                    apres du halals this is ice cold salad varung livin in the
                    city two owns yeah yeah yeah billi eilsh hotling bling
                    havayi hovo kovo yeee
                </p>
                <div className="imagesWrapper">
                    <Image.PreviewGroup items={[gravorImage, gravorImage]}>
                        <Image width={300} src={gravorImage} />
                        <Image width={300} src={gravorImage} />
                    </Image.PreviewGroup>
                </div>
            </div>
            <div className="answerFormWrapper">
                <TextArea rows={4} />
                <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    listType="picture"
                    defaultFileList={[...fileList]}
                >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
                <Button type="primary" block>
                    Ուղարկել
                </Button>
            </div>
        </div>
    );
};

export default OverflowSinglePage;
