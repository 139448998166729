import "./style.css";
import jnjelEsNkary from "../../Components/Logos/nkarJnjel.png";
import { useEffect, useState } from "react";
import newsLogo from "../..//Components/Logos/newsLogo.svg";

const Norutyunner = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
    });
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const resize = () => {
            setWindowSize({ width: window.innerWidth });
        };
        window.addEventListener("resize", resize);
        resize();
        return () => window.removeEventListener("resize", resize);
    }, []);
    useEffect(() => {
        if (windowSize.width < 1023) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowSize]);
    return (
        <div className="norutyunnerWrapper">
            {isMobile && (
                <div className="mobileHeading">
                    <img src={newsLogo} alt="" />
                    <p>Նորություններ</p>
                </div>
            )}
            <div className="norutyunWrapper">
                <img src={jnjelEsNkary} alt="" className="norutyunImage" />
                <p className="norutyunText">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Cumque harum, minima amet dolorum vitae cum necessitatibus
                    eaque obcaecati similique. Reiciendis porro rerum distinctio
                    velit sit autem culpa dignissimos eos consequuntur?
                </p>
                <p className="norutyunDate">16.03.2023</p>
            </div>
            <div className="norutyunWrapper">
                <img src={jnjelEsNkary} alt="" className="norutyunImage" />
                <p className="norutyunText">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Cumque harum, minima amet dolorum vitae cum necessitatibus
                    eaque obcaecati similique. Reiciendis porro rerum distinctio
                    velit sit autem culpa dignissimos eos consequuntur?
                </p>
                <p className="norutyunDate">16.03.2023</p>
            </div>
            <div className="norutyunWrapper">
                <img src={jnjelEsNkary} alt="" className="norutyunImage" />
                <p className="norutyunText">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Cumque harum, minima amet dolorum vitae cum necessitatibus
                    eaque obcaecati similique. Reiciendis porro rerum distinctio
                    velit sit autem culpa dignissimos eos consequuntur?
                </p>
                <p className="norutyunDate">16.03.2023</p>
            </div>
            <div className="norutyunWrapper">
                <img src={jnjelEsNkary} alt="" className="norutyunImage" />
                <p className="norutyunText">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Cumque harum, minima amet dolorum vitae cum necessitatibus
                    eaque obcaecati similique. Reiciendis porro rerum distinctio
                    velit sit autem culpa dignissimos eos consequuntur?
                </p>
                <p className="norutyunDate">16.03.2023</p>
            </div>
            <div className="norutyunWrapper">
                <img src={jnjelEsNkary} alt="" className="norutyunImage" />
                <p className="norutyunText">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Cumque harum, minima amet dolorum vitae cum necessitatibus
                    eaque obcaecati similique. Reiciendis porro rerum distinctio
                    velit sit autem culpa dignissimos eos consequuntur?
                </p>
                <p className="norutyunDate">16.03.2023</p>
            </div>
        </div>
    );
};

export default Norutyunner;
