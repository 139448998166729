import { useEffect, useState } from "react";
import announcementLogo from "../../Components/Logos/announcementLogo.svg";
import "./style.css";

const haytararutyunnerArr = [
    {
        id: "1",
        haytararutyunDate: "29.06.2023",
        haytararutyunText:
            "Գոյություն ունեն Lorem Ipsum-ի հատվածների բազմաթիվ տարբերակներ, բայց մեծամասնությունը փոփոխության է ենթարկվել ինչ-որ ձևով, ներարկված հումորի կամ պատահական բառերի միջոցով,որոնք նույնիսկ փոքր-ինչ հավատալի չեն թվում: Եթե ​​դուք պատրաստվում եք օգտագործել Lorem Ipsum-ի հատվածը, ապա պետք է վստահ լինեք, որ տեքստի մեջտեղում թաքնված որևէ ամոթալի բան",
    },
    {
        id: "2",
        haytararutyunDate: "28.06.2023",
        haytararutyunText:
            "Գոյություն ունեն Lorem Ipsum-ի հատվածների բազմաթիվ տարբերակներ, բայց մեծամասնությունը փոփոխության է ենթարկվել ինչ-որ ձևով, ներարկված հումորի կամ պատահական բառերի միջոցով,որոնք նույնիսկ փոքր-ինչ հավատալի չեն թվում: Եթե ​​դուք պատրաստվում եք օգտագործել Lorem Ipsum-ի հատվածը, ապա պետք է վստահ լինեք, որ տեքստի մեջտեղում թաքնված որևէ ամոթալի բան",
    },
    {
        id: "3",
        haytararutyunDate: "27.06.2023",
        haytararutyunText:
            "Գոյություն ունեն Lorem Ipsum-ի հատվածների բազմաթիվ տարբերակներ, բայց մեծամասնությունը փոփոխության է ենթարկվել ինչ-որ ձևով, ներարկված հումորի կամ պատահական բառերի միջոցով,որոնք նույնիսկ փոքր-ինչ հավատալի չեն թվում: Եթե ​​դուք պատրաստվում եք օգտագործել Lorem Ipsum-ի հատվածը, ապա պետք է վստահ լինեք, որ տեքստի մեջտեղում թաքնված որևէ ամոթալի բան",
    },
    {
        id: "4",
        haytararutyunDate: "27.06.2023",
        haytararutyunText:
            "Գոյություն ունեն Lorem Ipsum-ի հատվածների բազմաթիվ տարբերակներ, բայց մեծամասնությունը փոփոխության է ենթարկվել ինչ-որ ձևով, ներարկված հումորի կամ պատահական բառերի միջոցով,որոնք նույնիսկ փոքր-ինչ հավատալի չեն թվում: Եթե ​​դուք պատրաստվում եք օգտագործել Lorem Ipsum-ի հատվածը, ապա պետք է վստահ լինեք, որ տեքստի մեջտեղում թաքնված որևէ ամոթալի բան",
    },
    {
        id: "5",
        haytararutyunDate: "27.06.2023",
        haytararutyunText:
            "Գոյություն ունեն Lorem Ipsum-ի հատվածների բազմաթիվ տարբերակներ, բայց մեծամասնությունը փոփոխության է ենթարկվել ինչ-որ ձևով, ներարկված հումորի կամ պատահական բառերի միջոցով,որոնք նույնիսկ փոքր-ինչ հավատալի չեն թվում: Եթե ​​դուք պատրաստվում եք օգտագործել Lorem Ipsum-ի հատվածը, ապա պետք է վստահ լինեք, որ տեքստի մեջտեղում թաքնված որևէ ամոթալի բան",
    },
    {
        id: "6",
        haytararutyunDate: "27.06.2023",
        haytararutyunText:
            "Գոյություն ունեն Lorem Ipsum-ի հատվածների բազմաթիվ տարբերակներ, բայց մեծամասնությունը փոփոխության է ենթարկվել ինչ-որ ձևով, ներարկված հումորի կամ պատահական բառերի միջոցով,որոնք նույնիսկ փոքր-ինչ հավատալի չեն թվում: Եթե ​​դուք պատրաստվում եք օգտագործել Lorem Ipsum-ի հատվածը, ապա պետք է վստահ լինեք, որ տեքստի մեջտեղում թաքնված որևէ ամոթալի բան",
    },
    {
        id: "7",
        haytararutyunDate: "27.06.2023",
        haytararutyunText:
            "Գոյություն ունեն Lorem Ipsum-ի հատվածների բազմաթիվ տարբերակներ, բայց մեծամասնությունը փոփոխության է ենթարկվել ինչ-որ ձևով, ներարկված հումորի կամ պատահական բառերի միջոցով,որոնք նույնիսկ փոքր-ինչ հավատալի չեն թվում: Եթե ​​դուք պատրաստվում եք օգտագործել Lorem Ipsum-ի հատվածը, ապա պետք է վստահ լինեք, որ տեքստի մեջտեղում թաքնված որևէ ամոթալի բան",
    },
];

const Haytararutyunner = () => {
    const [content, setContent] = useState("");
    const [windowSize, setWindowSize] = useState({
        width: undefined,
    });
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const resize = () => {
            setWindowSize({ width: window.innerWidth });
        };
        window.addEventListener("resize", resize);
        resize();
        return () => window.removeEventListener("resize", resize);
    }, []);
    useEffect(() => {
        if (windowSize.width < 1023) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowSize]);
    useEffect(() => {
        let makeContent = haytararutyunnerArr.map((item) => {
            return (
                <div className="haytararutyunWrapper" key={item.id}>
                    <p className="haytararutyunText">
                        {item.haytararutyunText}
                    </p>
                    <p className="haytararutyunDate">
                        {item.haytararutyunDate}
                    </p>
                </div>
            );
        });
        setContent(makeContent);
    }, []);
    return (
        <div className="haytararutyunnerWrapper">
            {isMobile && (
                <div className="mobileHeading">
                    <img src={announcementLogo} alt="" />
                    <p>Հայտարարություններ</p>
                </div>
            )}
            {content}
        </div>
    );
};

export default Haytararutyunner;
