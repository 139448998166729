import "./style.css";
import leaderboard from "../Logos/leaderboard.svg";
import dwayneaxper from "../Logos/Account Image.jpg";
const LeaderBoard = () => {
    return (
        <div className="LeaderBoardWrapper">
            <div className="LeaderBoardHeading">
                <img src={leaderboard} alt="" className="LeaderBoardLogo" />
                Առաջատարներ
            </div>
            <div className="LeadersWrapper">
                <div>
                    <img src={dwayneaxper} alt="" />
                    <p>Dwayne Johnson</p>
                    <p className="score">1800</p>
                </div>
                <div>
                    <img src={dwayneaxper} alt="" />
                    <p>Dwayne Johnson</p>
                    <p className="score">1500</p>
                </div>
                <div>
                    <img src={dwayneaxper} alt="" />
                    <p>Dwayne Johnson</p>
                    <p className="score">1400</p>
                </div>
                <div>
                    <img src={dwayneaxper} alt="" />
                    <p>Dwayne Johnson</p>
                    <p className="score">1300</p>
                </div>
                <div>
                    <img src={dwayneaxper} alt="" />
                    <p>Dwayne Johnson</p>
                    <p className="score">1100</p>
                </div>
            </div>
        </div>
    );
};

export default LeaderBoard;
