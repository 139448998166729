import "./style.css";
import { Select, Button, Image, Rate, Input, Form, Upload } from "antd";
import plusLogo from "../../Components/Logos/plusLogo.svg";
import avatarLogo from "../../Components/Logos/Account Image.jpg";
import gravorImage from "../../Components/Logos/gravorImage.png";
import filterLogo from "../../Components/Logos/Filter.svg";
import ImgCrop from "antd-img-crop";
import closeLogo from "../../Components/Logos/closeLogo.svg";
import { useEffect, useState } from "react";
const { TextArea } = Input;

const ararkanerData = [
    "Բոլոր առարկաները",
    "Մաթեմատիկա",
    "Ֆիզիկա",
    "Հայոց լեզու",
    "Հայ գրականություն",
    "Կենսաբանություն",
    "Քիմիա",
    "Հայոց պատմություն",
];
const dasarannerData = ["Բոլոր դասարանները", "12", "11", "10", "9", "8", "7"];
const data = [
    {
        accountName: "Dwayne_333",
        accountImage: avatarLogo,
        fullName: "Dwayne Jhonson",
        subject: "Մաթեմատիկա",
        class: 10,
        text: "Erexeq brneq matemi gravorna sax lox en es gox em karecel em nkarem dasatus Sargsyanna voshm aneeeeeq",
        img: gravorImage /*ste url petqa lini vor srci mej drvi*/,
        rating: 5,
        date: "29.06.2023",
        id: 1,
    },
    {
        accountName: "Dwayne_333",
        accountImage: avatarLogo,
        fullName: "Dwayne Jhonson",
        subject: "Ֆիզիկա",
        class: 12,
        text: "Erexeq brneq matemi gravorna sax lox en es gox em karecel em nkarem dasatus Sargsyanna voshm aneeeeeq",
        img: gravorImage /*ste url petqa lini vor srci mej drvi*/,
        rating: 4,
        date: "29.06.2023",
        id: 2,
    },
    {
        accountName: "Dwayne_333",
        accountImage: avatarLogo,
        fullName: "Dwayne Jhonson",
        subject: "Քիմիա",
        class: 11,
        text: "Erexeq brneq matemi gravorna sax lox en es gox em karecel em nkarem dasatus Sargsyanna voshm aneeeeeq",
        img: gravorImage /*ste url petqa lini vor srci mej drvi*/,
        rating: 3,
        date: "29.06.2023",
        id: 3,
    },
];

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const Gravorner = () => {
    const [content, setContent] = useState("");
    const [filteredData, setFilteredData] = useState(data);
    const [selectedSubject, setSelectedSubject] = useState("Բոլոր առարկաները");
    const [selectedClass, setSelectedClass] = useState("Բոլոր դասարանները");
    const [add, setAdd] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [filter, setFilter] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handleSubjectChange = (value) => {
        setSelectedSubject(value);
        filterData(value, selectedClass);
    };

    const handleClassChange = (value) => {
        setSelectedClass(value);
        filterData(selectedSubject, value);
    };

    const onFinish = (values) => {
        console.log("Success:", values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const filterData = (subject, selectedClass) => {
        console.log(subject, selectedClass);
        if (
            subject === "Բոլոր առարկաները" &&
            selectedClass === "Բոլոր դասարանները"
        ) {
            setFilteredData(data);
        } else {
            const newData = data.filter(
                (item) =>
                    (subject === "Բոլոր առարկաները" ||
                        item.subject === subject) &&
                    (selectedClass === "Բոլոր դասարանները" ||
                        item.class == selectedClass)
            );
            setFilteredData(newData);
        }
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    useEffect(() => {
        const gravorner = filteredData.map((item) => {
            return (
                <div className="gravorWrapper" key={item.id}>
                    <div className="gravorTitleWrapper">
                        <img src={item.accountImage} alt="" />
                        <p>
                            {item.fullName} / {item.subject.toLowerCase()} /{" "}
                            {item.class}
                        </p>
                    </div>
                    <div className="gravorContentWrapper">
                        <p>{item.text}</p>
                        <Image.PreviewGroup
                            items={[item.img, item.img, item.img]}
                        >
                            <Image src={item.img} />
                        </Image.PreviewGroup>
                    </div>
                    <div className="gravorUnderContentWrapper">
                        <Rate allowClear={false} defaultValue={item.rating} />
                        <p className="gravorDate">{item.date}</p>
                    </div>
                </div>
            );
        });
        setContent(gravorner);
    }, [filteredData]);

    return (
        <div className="gravornerWrapper">
            {filter && (
                <div className="filters slide-in">
                    <div className="filtersHeader">
                        <h3>Ֆիլտրներ</h3>
                        <button
                            className="filtersClose"
                            onClick={() => setFilter(false)}
                        >
                            <img src={closeLogo} alt="" />
                        </button>
                    </div>
                    <div className="filtersContent">
                        <Select
                            size="large"
                            defaultValue={ararkanerData[0]}
                            onChange={handleSubjectChange}
                            style={{
                                width:
                                    windowSize.innerWidth > 767
                                        ? "200px"
                                        : "90%",
                            }}
                            options={ararkanerData.map((name) => ({
                                label: name,
                                value: name,
                            }))}
                        />
                        <Select
                            size="large"
                            defaultValue={dasarannerData[0]}
                            onChange={handleClassChange}
                            style={{
                                width:
                                    windowSize.innerWidth > 767
                                        ? "200px"
                                        : "90%",
                            }}
                            options={dasarannerData.map((name) => ({
                                label: name,
                                value: name,
                            }))}
                        />
                    </div>
                </div>
            )}
            {add ? (
                <div className="addFormWrapper">
                    <h2>Գրավորի ավելացում</h2>
                    <Form
                        name="basic"
                        style={{
                            width: windowSize.innerWidth > 768 ? 350 : "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            style={{
                                width: "100%",
                            }}
                            name="dasaran"
                            rules={[
                                {
                                    required: true,
                                    message: "Ընտրեք դասարանը!",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Ընտրեք դասարանը"
                                options={dasarannerData
                                    .slice(1)
                                    .map((name) => ({
                                        label: name,
                                        value: name,
                                    }))}
                            />
                        </Form.Item>

                        <Form.Item
                            style={{
                                width: "100%",
                            }}
                            name="ararka"
                            rules={[
                                {
                                    required: true,
                                    message: "Ընտրեք առարկան!",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Ընտրեք առարկան"
                                options={ararkanerData.slice(1).map((name) => ({
                                    label: name,
                                    value: name,
                                }))}
                            />
                        </Form.Item>

                        <Form.Item
                            style={{
                                width: "100%",
                            }}
                        >
                            <TextArea
                                rows={5}
                                maxLength={120}
                                style={{
                                    maxHeight: "10rem",
                                }}
                                placeholder="Անվանում կամ ուղղակի նամակ"
                            />
                        </Form.Item>

                        <ImgCrop rotationSlider>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                accept="image/png, image/jpeg, image/jpg"
                                required
                            >
                                {fileList.length < 3 && "+ Upload"}
                            </Upload>
                        </ImgCrop>

                        <Form.Item
                            style={{
                                width: "100%",
                                marginTop: "2rem",
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="submitBtn"
                                style={{
                                    width: "100%",
                                }}
                                size="large"
                            >
                                Հրապարակել
                            </Button>
                        </Form.Item>
                        <button
                            className="goBack"
                            onClick={() => setAdd(false)}
                        >
                            Հետ
                        </button>
                    </Form>
                </div>
            ) : (
                <div className="gravornerButtonsWrapper">
                    {windowSize.innerWidth > 767 ? (
                        <>
                            <Select
                                size="large"
                                defaultValue={ararkanerData[0]}
                                onChange={handleSubjectChange}
                                style={{
                                    width: 200,
                                }}
                                options={ararkanerData.map((name) => ({
                                    label: name,
                                    value: name,
                                }))}
                            />
                            <Select
                                size="large"
                                defaultValue={dasarannerData[0]}
                                onChange={handleClassChange}
                                style={{
                                    width: 200,
                                }}
                                options={dasarannerData.map((name) => ({
                                    label: name,
                                    value: name,
                                }))}
                            />
                        </>
                    ) : (
                        <button
                            className="filterButton"
                            onClick={() => setFilter(true)}
                        >
                            <img src={filterLogo} alt="" />
                        </button>
                    )}
                    <Button
                        type="primary"
                        className="avelacnelButton"
                        onClick={() => setAdd(true)}
                    >
                        Ավելացնել գրավոր
                        <img src={plusLogo} alt="" />
                    </Button>
                </div>
            )}
            {!add && content}
        </div>
    );
};

export default Gravorner;
