import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import newsLogo from "../Logos/newsLogo.svg";
import announcementLogo from "../Logos/announcementLogo.svg";
import journalLogo from "../Logos/journalLogo.svg";
import annonymLogo from "../Logos/annonymLogo.svg";
import convLogo from "../Logos/convLogo.svg";
import tournamentLogo from "../Logos/tournamentLogo.svg";

import "./style.css";

const MainNavigation = () => {
    const [presentLoc, setPresentLoc] = useState("");
    const location = useLocation();
    useEffect(() => {
        setPresentLoc(window.location.pathname.slice(1));
    }, [location]);
    return (
        <div className="mainNavigationWrapper">
            <ul>
                <li
                    id="Norutyunner"
                    className={
                        presentLoc === "Norutyunner" || presentLoc == ""
                            ? "active"
                            : ""
                    }
                >
                    <Link to="Norutyunner">
                        <img src={newsLogo} alt="" />
                        Նորություններ
                    </Link>
                </li>
                <li
                    id="Haytararutyunner"
                    className={
                        presentLoc === "Haytararutyunner" ? "active" : ""
                    }
                >
                    <Link to="Haytararutyunner">
                        <img src={announcementLogo} alt="" />
                        Հայտարարություններ
                    </Link>
                </li>
                <li
                    id="Gravorner"
                    className={presentLoc === "Gravorner" ? "active" : ""}
                >
                    <Link to="Gravorner">
                        <img src={journalLogo} alt="" />
                        Գրավորներ
                    </Link>
                </li>
                <li
                    id="Gaghtni_Namakner"
                    className={
                        presentLoc === "Gaghtni_Namakner" ? "active" : ""
                    }
                >
                    <Link to="Gaghtni_Namakner">
                        <img src={annonymLogo} alt="" />
                        Գաղտնի նամակներ
                    </Link>
                </li>
                <li
                    id="PhysMath_overflow"
                    className={
                        presentLoc === "PhysMath_overflow" ? "active" : ""
                    }
                >
                    <Link to="PhysMath_overflow">
                        <img src={convLogo} alt="" />
                        Ֆիզմաթ overflow
                    </Link>
                </li>
                <li
                    id="Mrcuytner"
                    className={presentLoc === "Mrcuytner" ? "active" : ""}
                >
                    <Link to="Mrcuytner">
                        <img src={tournamentLogo} alt="" />
                        Մրցույթներ
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default MainNavigation;
