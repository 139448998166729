import { BrowserRouter as Router } from "react-router-dom";
import { useState, useEffect } from "react";
import Routes from "./Routes";
import Header from "./Components/Header";
import MainNavigation from "./Components/MainNavigation";
import FriendBar from "./Components/FriendBar";
import Login from "./Components/Login";
import { useAuth } from "./Components/AuthContext";
import "./App.css";

function App() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
    });
    const [isMobile, setIsMobile] = useState(false);
    const { isAuthenticated, token } = useAuth();
    useEffect(() => {
        const resize = () => {
            setWindowSize({ width: window.innerWidth });
        };
        window.addEventListener("resize", resize);
        resize();
        return () => window.removeEventListener("resize", resize);
    }, []);
    useEffect(() => {
        if (windowSize.width < 1023) {
            setIsMobile(true);
            localStorage.setItem("screenWidth", windowSize.width);
        } else {
            setIsMobile(false);
        }
    }, [windowSize]);
    useEffect(() => {
        console.log(localStorage.getItem("userData"));
    }, [localStorage.getItem("userData")]);

    return (
        <div className="app" id="app">
            <Router>
                {isAuthenticated ? (
                    <>
                        <Header isMobile={isMobile} />
                        <div className="wrapper">
                            {!isMobile && <MainNavigation />}
                            <Routes />
                            {!isMobile && <FriendBar />}
                        </div>
                    </>
                ) : (
                    <Login />
                )}
            </Router>
        </div>
    );
}

export default App;
