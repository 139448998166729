import React, { useState } from "react";
import logo from "../Logos/PhysmathSocialLogo.png";
import { Button, Form, Input, Modal } from "antd";
import { useAuth } from "../AuthContext";
import "./style.css";

const apiKey = process.env.REACT_APP_API_KEY;

const Login = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState([]);
    const [token, setToken] = useState("");
    const { signIn } = useAuth();
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    async function loginRequest(login, password) {
        setLoading(true);
        try {
            const response = await fetch(
                `https://physmath-social.onrender.com/api/Users/login?UserName=${login}&Password=${password}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        ApiKey: apiKey,
                    },
                }
            );

            const status = response.status;
            const data = await response.json();

            if (status === 200 || status === 201) {
                signIn(data.jwt);
                localStorage.setItem("userData", JSON.stringify(data));
            } else {
                setError(true);
                console.error("An error occurred:", "Login failed");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(true);
            console.error(
                "An error occurred:",
                "Login failed. Please try again later."
            );
        }
    }
    const onFinish = (values) => {
        loginRequest(values.username, values.password);
    };
    return (
        <div className="loginPageWrapper">
            <img src={logo} alt="" className="logo" />
            <div className="circle f"></div>
            <div className="circle s"></div>
            <h1>Բարի գալուստ PhysMath social</h1>
            <div className="circle t"></div>
            <div className="loginFormWrapper">
                <h2>Մուտք գործեք շարունակելու համար</h2>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: "Մուտքագրեք ձեր օգտանունը",
                            },
                        ]}
                    >
                        <Input
                            placeholder="Օգտանուն"
                            className="usernameInput"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Մուտքագրեք ձեր գաղտնաբառը",
                            },
                        ]}
                    >
                        <Input.Password placeholder="Գաղտնաբառ" />
                    </Form.Item>
                    <a onClick={showModal}>Մոռացե՞լ եք գաղտնաբառը։</a>
                    <Modal
                        title="Մոռացե՞լ եք գաղտնաբառը"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        centered
                    >
                        <p>
                            Ձեր իսկ ապահովության համար խնդրում ենք գաղտնաբառը
                            մոռանալու դեպքում գրել մեր instagram-ին:
                        </p>
                        <a
                            href="https://instagram.com/physmath.social?igshid=OGQ5ZDc2ODk2ZA=="
                            target="_blank"
                        >
                            Physmath.social instagram
                        </a>
                    </Modal>
                    {error && (
                        <p className="loginError">
                            Սխալ օգտանուն կամ գաղտնաբառ
                        </p>
                    )}
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                        >
                            Մուտք
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;
