import plusLogo from "../../Components/Logos/plusLogo.svg";
import accountImage from "../../Components/Logos/Account Image.jpg";
import { Link } from "react-router-dom";
import { Button, Input, Form, Upload } from "antd";
import { useState, useEffect } from "react";
import ImgCrop from "antd-img-crop";
import "./style.css";
const { Search } = Input;
const { TextArea } = Input;

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const FizmatOverflow = () => {
    const onSearch = (value) => console.log(value);
    const [add, setAdd] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };
    const onFinish = (values) => {
        console.log("Success:", values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleClick = (e) => {
        console.log(e.target.parentElement.id);
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <div className="fizmatOverflowWrapper">
            {add ? (
                <div className="addFormWrapper">
                    <h2>Հարցի ավելացում</h2>
                    <Form
                        name="basic"
                        style={{
                            width: windowSize.innerWidth > 768 ? 350 : "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            style={{
                                width: "100%",
                            }}
                        >
                            <TextArea
                                rows={5}
                                maxLength={120}
                                style={{
                                    maxHeight: "10rem",
                                }}
                                placeholder="Գրեք ձեր հարցը այստեղ"
                            />
                        </Form.Item>

                        <ImgCrop rotationSlider>
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onChange}
                                onPreview={onPreview}
                                accept="image/png, image/jpeg, image/jpg"
                                required
                            >
                                {fileList.length < 3 && "+ Upload"}
                            </Upload>
                        </ImgCrop>

                        <Form.Item
                            style={{
                                width: "100%",
                                marginTop: "2rem",
                            }}
                        >
                            <Button
                                type="primary"
                                className="submitBtn"
                                htmlType="submit"
                                style={{
                                    width: "100%",
                                }}
                                size="large"
                            >
                                Հրապարակել
                            </Button>
                        </Form.Item>
                        <button
                            className="goBack"
                            onClick={() => setAdd(false)}
                        >
                            Հետ
                        </button>
                    </Form>
                </div>
            ) : (
                <div className="fizmatOverflowButtonsWrapper">
                    <Search
                        placeholder="Որոնել..."
                        onSearch={onSearch}
                        allowClear
                        enterButton
                        size="large"
                    />
                    <Button
                        type="primary"
                        className="avelacnelButton"
                        onClick={() => setAdd(true)}
                    >
                        {windowSize.innerWidth > 768 && "Ավելացնել նամակ"}

                        <img src={plusLogo} alt="" />
                    </Button>
                </div>
            )}
            {!add && (
                <>
                    <div className="harcWrapper">
                        <div>
                            <img src={accountImage} alt="" />
                            <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                        </div>
                        <Link
                            to={"2983123213"}
                            className="helpButton"
                            onClick={(e) => handleClick(e)}
                        >
                            ՕԳՆԵԼ
                        </Link>
                    </div>
                    <div className="harcWrapper">
                        <div>
                            <img src={accountImage} alt="" />
                            <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                        </div>
                        <Link
                            to={"2983123213"}
                            className="helpedButton"
                            onClick={(e) => handleClick(e)}
                        >
                            ՊԱՏԱՍԽԱՆՎԱԾ
                        </Link>
                    </div>
                    <div className="harcWrapper">
                        <div>
                            <img src={accountImage} alt="" />
                            <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                        </div>
                        <Link
                            to={"2983123213"}
                            className="helpButton"
                            onClick={(e) => handleClick(e)}
                        >
                            ՕԳՆԵԼ
                        </Link>
                    </div>
                    <div className="harcWrapper">
                        <div>
                            <img src={accountImage} alt="" />
                            <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                        </div>
                        <Link
                            to={"2983123213"}
                            className="helpedButton"
                            onClick={(e) => handleClick(e)}
                        >
                            ՊԱՏԱՍԽԱՆՎԱԾ
                        </Link>
                    </div>
                    <div className="harcWrapper">
                        <div>
                            <img src={accountImage} alt="" />
                            <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                        </div>
                        <Link
                            to={"2983123213"}
                            className="helpButton"
                            onClick={(e) => handleClick(e)}
                        >
                            ՕԳՆԵԼ
                        </Link>
                    </div>
                    <div className="harcWrapper">
                        <div>
                            <img src={accountImage} alt="" />
                            <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                        </div>
                        <Link
                            to={"2983123213"}
                            className="helpedButton"
                            onClick={(e) => handleClick(e)}
                        >
                            ՊԱՏԱՍԽԱՆՎԱԾ
                        </Link>
                    </div>
                    <div className="harcWrapper">
                        <div>
                            <img src={accountImage} alt="" />
                            <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                        </div>
                        <Link
                            to={"2983123213"}
                            className="helpButton"
                            onClick={(e) => handleClick(e)}
                        >
                            ՕԳՆԵԼ
                        </Link>
                    </div>
                    <div className="harcWrapper">
                        <div>
                            <img src={accountImage} alt="" />
                            <p>Ո՞վ կարա օգնի Հովհաննիսյանի 3․34ը գրեմ</p>
                        </div>
                        <Link
                            to={"2983123213"}
                            className="helpedButton"
                            onClick={(e) => handleClick(e)}
                        >
                            ՊԱՏԱՍԽԱՆՎԱԾ
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
};

export default FizmatOverflow;
