import React from "react";
import App from "./App";
import { AuthProvider } from "./Components/AuthContext";
import { createRoot } from "react-dom/client";
import "./index.css";

const rootElement = document.getElementById("root");

const rootInstance = createRoot(rootElement);

rootInstance.render(
    <AuthProvider>
        <App />
    </AuthProvider>
);
