import telegramLogo from "../../Components/Logos/telegramLogo.svg";
import instagramLogo from "../../Components/Logos/instagramLogo.svg";
import facebookLogo from "../../Components/Logos/facebookLogo.svg";
import "./style.css";

const MerMasin = () => {
    return (
        <div className="merMasinWrapper">
            <div>
                <h2>Մեր մասին</h2>
                <p>
                    Մենք ֆիզմաթ դպրոցում սովորող աշակերտներ ենք։ Ընդունվելով
                    ֆիզմաթ դպրոց ժամանակի ընթացքում ձեռք ենք բերում սեր
                    ծրագրավորման նկատմամբ։ 2023 թվականի ամռանը որոշում ենք
                    ստեղծել այնպիսի կայք, որի նմանը դեռ չկա։
                </p>
                <p>
                    PhysMath social-ը ստեղծելուց մեր առաջ դրված նպատակն էր
                    ստեղծել այնպիսի բան, որից կօգտվենք մենք նույնպես և որով
                    կօգնենք աշակերտներին իրենց ուսումը ավելի հեշտ և հետաքրքիր
                    անցկացնել։
                </p>
                <p>
                    Վստահ ենք այս հարթակը ձեզ կօգնի շատ հարցերում։ Խորհուրդների,
                    բողոքների կամ առաջարկների համար կայքում տեղ է գտել նաև կապ
                    բաժինը։ Մենք ցանկանում ենք մնալ անոնիմ։ Շնորհակալություն։
                </p>
                <div className="socialNetworks">
                    <a
                        href="https://instagram.com/physmath.social?igshid=OGQ5ZDc2ODk2ZA=="
                        target="_blank"
                    >
                        <img src={instagramLogo} alt="TG" />
                    </a>
                    <a href="https://t.me/PhysmathSocial" target="_blank">
                        <img src={telegramLogo} alt="IG" />
                    </a>
                    {/* <a href="">
                            <img src={facebookLogo} alt="FB" />
                        </a> */}
                </div>
            </div>
        </div>
    );
};

export default MerMasin;
