import Basketball from "../../Components/Logos/Basketball.png";
import Football from "../../Components/Logos/Football.png";
import Volleyball from "../../Components/Logos/Volleyball.png";
import Chess from "../../Components/Logos/Chess.png";
import { Link } from "react-router-dom";
import "./style.css";

const Mrcuytner = () => {
    return (
        <div className="mrcuytnerWrapper">
            <div className="mrcuytWrapper">
                <div className="mrcuytContentWrapper">
                    <div>
                        <p>Սկիզբ: 16.05.2024</p>
                        <p>Սպասվող խաղ: 10ե - 10ա</p>
                        <p>Սպասվող խաղի ամսաթիվ: 17.05.2024</p>
                    </div>
                    <div className="mrcuytButtonsWrapper">
                        <p>Ընտրեք դասարանը:</p>
                        <div className="mrcuytNavBtns">
                            <Link to={"Football#7"}>7</Link>
                            <Link to={"Football#8"}>8</Link>
                            <Link to={"Football#9"}>9</Link>
                            <Link to={"Football#10"}>10</Link>
                            <Link to={"Football#11"}>11</Link>
                            <Link to={"Football#12"}>12</Link>
                        </div>
                    </div>
                </div>
                <img src={Football} alt="" />
            </div>
            <div className="mrcuytWrapper">
                <div className="mrcuytContentWrapper">
                    <div>
                        <p>Սկիզբ: 16.05.2024</p>
                        <p>Սպասվող խաղ: 10ե - 10ա</p>
                        <p>Սպասվող խաղի ամսաթիվ: 17.05.2024</p>
                    </div>
                    <div className="mrcuytButtonsWrapper">
                        <p>Ընտրեք դասարանը:</p>
                        <div className="mrcuytNavBtns">
                            <Link to={"Basketball#7"}>7</Link>
                            <Link to={"Basketball#8"}>8</Link>
                            <Link to={"Basketball#9"}>9</Link>
                            <Link to={"Basketball#10"}>10</Link>
                            <Link to={"Basketball#11"}>11</Link>
                            <Link to={"Basketball#12"}>12</Link>
                        </div>
                    </div>
                </div>
                <img src={Basketball} alt="" />
            </div>
            <div className="mrcuytWrapper">
                <div className="mrcuytContentWrapper">
                    <div>
                        <p>Սկիզբ: 16.05.2024</p>
                        <p>Սպասվող խաղ: 10ե - 10ա</p>
                        <p>Սպասվող խաղի ամսաթիվ: 17.05.2024</p>
                    </div>
                    <div className="mrcuytButtonsWrapper">
                        <p>Ընտրեք դասարանը:</p>
                        <div className="mrcuytNavBtns">
                            <Link to={"Volleyball#7"}>7</Link>
                            <Link to={"Volleyball#8"}>8</Link>
                            <Link to={"Volleyball#9"}>9</Link>
                            <Link to={"Volleyball#10"}>10</Link>
                            <Link to={"Volleyball#11"}>11</Link>
                            <Link to={"Volleyball#12"}>12</Link>
                        </div>
                    </div>
                </div>
                <img src={Volleyball} alt="" />
            </div>
            <div className="mrcuytWrapper">
                <div className="mrcuytContentWrapper">
                    <div>
                        <p>Սկիզբ: 16.05.2024</p>
                        <p>Սպասվող խաղ: 10ե - 10ա</p>
                        <p>Սպասվող խաղի ամսաթիվ: 17.05.2024</p>
                    </div>
                    <div className="mrcuytButtonsWrapper">
                        <p>Ընտրեք դասարանը:</p>
                        <div className="mrcuytNavBtns">
                            <Link to={"Chess#7"}>7</Link>
                            <Link to={"Chess#8"}>8</Link>
                            <Link to={"Chess#9"}>9</Link>
                            <Link to={"Chess#10"}>10</Link>
                            <Link to={"Chess#11"}>11</Link>
                            <Link to={"Chess#12"}>12</Link>
                        </div>
                    </div>
                </div>
                <img src={Chess} alt="" />
            </div>
        </div>
    );
};

export default Mrcuytner;
