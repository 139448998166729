import PhysMathSocialLogo from "../Logos/PhysmathSocialLogo.png";
import DwayneAxper from "../Logos/Account Image.jpg";
import newsLogo from "../Logos/newsLogo.svg";
import announcementLogo from "../Logos/announcementLogo.svg";
import journalLogo from "../Logos/journalLogo.svg";
import annonymLogo from "../Logos/annonymLogo.svg";
import convLogo from "../Logos/convLogo.svg";
import tournamentLogo from "../Logos/tournamentLogo.svg";
import kapLogo from "../Logos/kapLogo.svg";
import infoLogo from "../Logos/infoLogo.svg";
import telegramLogo from "../Logos/telegramLogo.svg";
import instagramLogo from "../Logos/instagramLogo.svg";
import facebookLogo from "../Logos/facebookLogo.svg";
import { Twirl as Hamburger } from "hamburger-react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

const Header = ({ isMobile }) => {
    const [isOpen, setOpen] = useState(false);
    const [presentLoc, setPresentLoc] = useState("");
    const location = useLocation();
    useEffect(() => {
        setPresentLoc(window.location.pathname.slice(1));
    }, [location]);
    return (
        <div className="headerWrapper">
            <div className="headerLogo">
                <Link to="">
                    <img
                        src={PhysMathSocialLogo}
                        alt="LogoHere"
                        className="headerLogoImage"
                    />
                    <p className="headerLogoText">
                        <span>PhysMath</span> Social
                    </p>
                </Link>
            </div>
            {isOpen && (
                <div className="fullNavigation">
                    <div className="headerLogoNavigation">
                        <Link to="" onClick={() => setOpen(false)}>
                            <img
                                src={PhysMathSocialLogo}
                                alt="LogoHere"
                                className="headerLogoImage"
                            />
                            <p className="headerLogoText">
                                <span>PhysMath</span> Social
                            </p>
                        </Link>
                    </div>
                    <div className="mainNavigationWrapper">
                        <ul>
                            <li
                                id="Im_ejy"
                                className={
                                    presentLoc == "Im_ejy" ? "active" : ""
                                }
                            >
                                <Link
                                    to="Im_ejy"
                                    onClick={() => setOpen(false)}
                                >
                                    <img
                                        src={DwayneAxper}
                                        alt="JNJIR INDZZZZZ"
                                        className="accountImage"
                                    />
                                    <p>Իմ էջը</p>
                                </Link>
                            </li>
                            <li
                                id="Norutyunner"
                                className={
                                    presentLoc == "Norutyunner" ||
                                    presentLoc == ""
                                        ? "active"
                                        : ""
                                }
                            >
                                <Link
                                    to="Norutyunner"
                                    onClick={() => setOpen(false)}
                                >
                                    <img src={newsLogo} alt="" />
                                    Նորություններ
                                </Link>
                            </li>
                            <li
                                id="Haytararutyunner"
                                className={
                                    presentLoc == "Haytararutyunner"
                                        ? "active"
                                        : ""
                                }
                            >
                                <Link
                                    to="Haytararutyunner"
                                    onClick={() => setOpen(false)}
                                >
                                    <img src={announcementLogo} alt="" />
                                    Հայտարարություններ
                                </Link>
                            </li>
                            <li
                                id="Gravorner"
                                className={
                                    presentLoc == "Gravorner" ? "active" : ""
                                }
                            >
                                <Link
                                    to="Gravorner"
                                    onClick={() => setOpen(false)}
                                >
                                    <img src={journalLogo} alt="" />
                                    Գրավորներ
                                </Link>
                            </li>
                            <li
                                id="Gaghtni_Namakner"
                                className={
                                    presentLoc == "Gaghtni_Namakner"
                                        ? "active"
                                        : ""
                                }
                            >
                                <Link
                                    to="Gaghtni_Namakner"
                                    onClick={() => setOpen(false)}
                                >
                                    <img src={annonymLogo} alt="" />
                                    Գաղտնի նամակներ
                                </Link>
                            </li>
                            <li
                                id="PhysMath_overflow"
                                className={
                                    presentLoc == "PhysMath_overflow"
                                        ? "active"
                                        : ""
                                }
                            >
                                <Link
                                    to="PhysMath_overflow"
                                    onClick={() => setOpen(false)}
                                >
                                    <img src={convLogo} alt="" />
                                    Ֆիզմաթ overflow
                                </Link>
                            </li>
                            <li
                                id="Mrcuytner"
                                className={
                                    presentLoc == "Mrcuytner" ? "active" : ""
                                }
                            >
                                <Link
                                    to="Mrcuytner"
                                    onClick={() => setOpen(false)}
                                >
                                    <img src={tournamentLogo} alt="" />
                                    Մրցույթներ
                                </Link>
                            </li>
                            <li
                                id="Kap"
                                className={presentLoc == "Kap" ? "active" : ""}
                            >
                                <Link to="Kap" onClick={() => setOpen(false)}>
                                    <img src={kapLogo} alt="" />
                                    Կապ
                                </Link>
                            </li>
                            <li
                                id="Mer_Masin"
                                className={
                                    presentLoc == "Mer_Masin" ? "active" : ""
                                }
                            >
                                <Link
                                    to="Mer_Masin"
                                    onClick={() => setOpen(false)}
                                >
                                    <img src={infoLogo} alt="" />
                                    Մեր մասին
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="socialNetworks">
                        <a href="https://t.me/PhysmathSocial" target="_blank">
                            <img src={telegramLogo} alt="TG" />
                        </a>
                        <a
                            href="https://instagram.com/physmath.social?igshid=OGQ5ZDc2ODk2ZA=="
                            target="_blank"
                        >
                            <img src={instagramLogo} alt="IG" />
                        </a>
                    </div>
                </div>
            )}
            <div className="headerNav">
                {isMobile ? (
                    <Hamburger
                        toggled={isOpen}
                        toggle={setOpen}
                        size={20}
                        color="#1e3583"
                    />
                ) : (
                    <ul className="nav">
                        <li>
                            <Link to="Mer_Masin">Մեր մասին</Link>
                        </li>
                        <li>
                            <Link to="Kap">Կապ</Link>
                        </li>
                        <li>
                            <Link to="Im_ejy">
                                <img src={DwayneAxper} alt="JNJIR INDZZZZZ" />
                            </Link>
                        </li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Header;
