import "./style.css";

const Kap = () => {
    return (
        <div className="kapWrapper">
            <div>
                <h2>Կապ</h2>
                <p>
                    Մեզ հետ կապ հաստատելու համար խնդրում ենք գրել մեր{" "}
                    <a
                        href="https://instagram.com/physmath.social?igshid=OGQ5ZDc2ODk2ZA=="
                        target="_blank"
                    >
                        Instagram-ին
                    </a>{" "}
                    կամ{" "}
                    <a href="https://t.me/PhysmathSocial" target="_blank">
                        Telegram-ին
                    </a>
                    ։
                </p>
                <p>Սիրով Physmath social ադմինիստրացիա:</p>
            </div>
        </div>
    );
};

export default Kap;
