import plusLogo from "../../Components/Logos/plusLogo.svg";
import whoLogo from "../../Components/Logos/whoLogo.svg";
import { useState, useEffect } from "react";
import { Button, Input, Form } from "antd";

import "./style.css";
const { TextArea } = Input;
const { Search } = Input;

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const GaxtniNamakner = () => {
    const onSearch = (value) => console.log(value);
    const [add, setAdd] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const onFinish = (values) => {
        console.log("Success:", values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);
    return (
        <div className="gaxtniNamaknerWrapper">
            {add ? (
                <div className="addFormWrapper">
                    <h2>Գաղտնի նամակի ավելացում</h2>
                    <Form
                        name="basic"
                        style={{
                            width: windowSize.innerWidth > 768 ? 350 : "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            style={{
                                width: "100%",
                            }}
                        >
                            <TextArea
                                rows={5}
                                maxLength={120}
                                style={{
                                    maxHeight: "10rem",
                                }}
                                placeholder="Գրիր քո նամակը այստեղ"
                            />
                        </Form.Item>

                        <Form.Item
                            style={{
                                width: "100%",
                                marginTop: "2rem",
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="submitBtn"
                                style={{
                                    width: "100%",
                                }}
                                size="large"
                            >
                                Հրապարակել
                            </Button>
                        </Form.Item>
                        <button
                            className="goBack"
                            onClick={() => setAdd(false)}
                        >
                            Հետ
                        </button>
                    </Form>
                </div>
            ) : (
                <div className="gaxtniNamaknerButtonsWrapper">
                    <Search
                        placeholder="Որոնել..."
                        onSearch={onSearch}
                        allowClear
                        enterButton
                        size="large"
                        onFocus={() => console.log("Focused")}
                    />
                    <Button
                        type="primary"
                        className="avelacnelButton"
                        onClick={() => setAdd(true)}
                    >
                        {windowSize.innerWidth > 768 && "Ավելացնել նամակ"}
                        <img src={plusLogo} alt="" />
                    </Button>
                </div>
            )}
            {!add && (
                <>
                    <div className="gaxtniNamakWrapper">
                        <div className="gaxtniNamakTitleWrapper">
                            <img src={whoLogo} alt="" />
                            <p>Անոնիմ նամակ #234</p>
                        </div>
                        <p className="gaxtniNamakContent">
                            12e i Gevy amenaujex cragravoroxna es ashxarhi vri
                            ara jogaq vabshe saxd lorem ipsum dor Gevorg en amet
                            siu messi ronaldo apres du halals this is ice cold
                            salad varung livin in the city two owns yeah yeah
                            yeah billi eilsh hotling bling havayi hovo kovo yeee
                        </p>
                        <p className="gaxtniNamakDate">29.06.2023</p>
                    </div>
                    <div className="gaxtniNamakWrapper">
                        <div className="gaxtniNamakTitleWrapper">
                            <img src={whoLogo} alt="" />
                            <p>Անոնիմ նամակ #234</p>
                        </div>
                        <p className="gaxtniNamakContent">
                            12e i Gevy amenaujex cragravoroxna es ashxarhi vri
                            ara jogaq vabshe saxd lorem ipsum dor Gevorg en amet
                            siu messi ronaldo apres du halals this is ice cold
                            salad varung livin in the city two owns yeah yeah
                            yeah billi eilsh hotling bling havayi hovo kovo yeee
                        </p>
                        <p className="gaxtniNamakDate">29.06.2023</p>
                    </div>
                    <div className="gaxtniNamakWrapper">
                        <div className="gaxtniNamakTitleWrapper">
                            <img src={whoLogo} alt="" />
                            <p>Անոնիմ նամակ #234</p>
                        </div>
                        <p className="gaxtniNamakContent">
                            12e i Gevy amenaujex cragravoroxna es ashxarhi vri
                            ara jogaq vabshe saxd lorem ipsum dor Gevorg en amet
                            siu messi ronaldo apres du halals this is ice cold
                            salad varung livin in the city two owns yeah yeah
                            yeah billi eilsh hotling bling havayi hovo kovo yeee
                        </p>
                        <p className="gaxtniNamakDate">29.06.2023</p>
                    </div>
                </>
            )}
        </div>
    );
};

export default GaxtniNamakner;
